import { computed } from 'vue'
import process from 'process'

export const ENV = {
  VUE_APP_SUPPORT_EMAIL_ADDRESS: computed(() => {
    return process?.env?.VUE_APP_SUPPORT_EMAIL_ADDRESS
  }),
  VUE_APP_FEEDBACK_EMAIL_ADDRESS: computed(() => {
    return process?.env?.VUE_APP_FEEDBACK_EMAIL_ADDRESS || process?.env?.VUE_APP_SUPPORT_EMAIL_ADDRESS
  }),
  NODE_ENV_IS_DEV: computed(() => {
    return process?.env?.NODE_ENV === 'dev' || process?.env?.NODE_ENV === 'development'
  })
}
