<template>
    <!-- Navbar -->
    <div class="container">
       <nav class="navbar is-white">
        <div class="navbar-brand">

            <div class="navbar-burger" data-target="bdr-menu" @click="burgerMenu()" :class="{
                'is-active': burgerActive
            }">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div id="bdr-menu" class="navbar-menu" :class="{
            'is-active': burgerActive
        }">
            <div class="navbar-start ml-4">
                <router-link class="navbar-item has-text-weight-semibold" :class="{
                    'is-active dawe-active-menu': currentRoute.name === 'Home',
                }" to="/">Home</router-link>
                <router-link class="navbar-item has-text-weight-semibold" :class="{
                    'is-active dawe-active-menu': currentRoute.name === 'Download Template',
                }" to="/template/download">Download biodiversity
                    data templates</router-link>
                <router-link class="navbar-item has-text-weight-semibold" :class="{
                    'is-active dawe-active-menu': currentRoute.name === 'Generate Secure Upload Link',
                }" to="/template/secure-link">Request link to
                    upload data</router-link>
              <a class="navbar-item has-text-weight-semibold"
                 :href="`mailto:${ENV.VUE_APP_FEEDBACK_EMAIL_ADDRESS.value}`" target="_blank">
                Provide feedback on this page</a>

            </div>

            <div class="navbar-end">

            </div>
        </div>
    </nav>
    </div>
    <div class="navbar-under">
    </div>

    <!-- Breadcrumb -->
    <section class="section pb-0 pt-0">
        <div class="container">
            <section class="section pb-0">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li v-if="currentRoute.name !== 'Home'" :class="{
                            'is-active': currentRoute.name === 'Home',
                            'has-text-weight-semibold': currentRoute.name !== 'Home'
                        }">
                            <router-link to="/">Home</router-link>
                        </li>

                        <li v-for="routeName of breadCrumbStack" :key="routeName" :class="{
                            'is-active': currentRoute.name === routeName,
                            'has-text-weight-semibold': currentRoute.name !== routeName && routeName !== '404'
                        }">
                            <router-link v-if="routeName !== '404'" :to="{ name: routeName }">{{ routeName }}
                            </router-link>
                            <div class="dawe-404" v-else>404</div>
                        </li>
                    </ul>
                </nav>
            </section>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import router, { breadCrumbStack } from '@/router'
import { ENV } from '@/constants'

const currentRoute = router.currentRoute

const burgerActive = ref(false)

const burgerMenu = () => {
  burgerActive.value = !burgerActive.value
}
</script>

<style scoped lang="scss">
.dawe-404 {
    padding-left: 0.75em
}

.dawe-active-menu {
    border-bottom: solid;
    border-bottom-color: #8BDCF8;
}

.navbar-under {
  min-height: 2em;
  background-color: #083A42;
}
</style>
